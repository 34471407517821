import Vue from 'vue'
import Component from 'vue-class-component'
import Pagination from "@/components/paginaiton.vue";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Pagination,
    Empty,
  },
})
export class listMixin extends Vue {
  ifShowEmpty: any = false;
  filters: any = {
    search: "",//搜索
    sort_field: "创建时间",
    sort_value: "0",// 排序
    total_page: 0, // 总页码
    total_count: 0, // 总条数
    page_count: 10, //每页条数
    current_page: 1, // 当前页码
    startTime: null,
    endTime: null
  };
  selectedMessages: any = [];
  ifSelectAll: any = false;// 当前列表是否全部选中
  getList() { }
  /**
    * @description 搜索
    */
  search() {
    this.filters.current_page = 1;
    this.selectedMessages = [];
    this.getList();
  }
  /**
   * @description 处理排序变化
   */
  private handleSortChange(ev: any) {
    this.filters.sort_field = ev["prop"];
    this.filters.sort_value = ev["order"] === "ascending" ? "1" : "0";
    this.search();
  }
  /**
   * @description 处理翻页器的当前页变化
   */
  private handleCurrentPageChange(val: any) {
    this.filters.current_page = val;
    this.getList();
  }
  /**
   * @description 处理翻页器的每页条数变化
   */
  private handleCurrentSizeChange(val: any) {
    this.filters.current_page = 1;
    this.filters.page_count = val;
    this.getList();
  }
  updataIfSelectAll(d: any) {
    let restlt: any = true;
    d.forEach((ele: any) => {
      if (!ele.selected) {
        restlt = false
      }
    });
    this.ifSelectAll = restlt;
  }
  // 选中当前页面数据
  selectAll(d: any, key: any) {
    d.forEach((ele: any) => {
      if (!ele.selected) {
        ele.selected = true;
        const index: any = this.selectedMessages.findIndex((val: any) => {
          return val[key] == ele[key]
        })
        if (index == -1) {
          this.selectedMessages.push(ele)
        }
      }
    });
    this.updataIfSelectAll(d);
  }
  // 取消选中当前页面数据
  clearSelectAll(d: any, key: any) {
    d.forEach((ele: any) => {
      if (ele.selected) {
        ele.selected = false;
        const index = this.selectedMessages.findIndex((val: any) => {
          return val[key] == ele[key]
        })
        if (index !== -1) {
          this.selectedMessages.splice(index, 1)
        }
      }
    });
    this.updataIfSelectAll(d);
  }
  // 清除全部选中数据
  resetSelect(d: any) {
    d.forEach((ele: any) => {
      if (ele.selected) {
        ele.selected = false;
        this.selectedMessages = []
      }
    });
    this.updataIfSelectAll(d);
  }
  // 勾选当前列表已选中数据(每次获取数据时需要调用)
  initSelectData(d: any, key: any) {
    d.forEach((ele: any) => {
      const index = this.selectedMessages.findIndex((val: any) => {
        return val[key] == ele[key]
      })
      if (index !== -1) {
        ele.selected = true;
      } else {
        ele.selected = false;
      }
    });
    this.updataIfSelectAll(d);
  }
  // 选中改变
  selectChange(d: any, item: any, uniKey: any) {
    if (item.selected) {
      this.selectedMessages.push(item);
    } else {
      const index = this.selectedMessages.findIndex((val: any) => {
        return val[uniKey] = item[uniKey]
      });
      this.selectedMessages.splice(index, 1);
    }
    this.updataIfSelectAll(d);
  }
  mounted() {
    // const h = document.body.clientHeight;
    // if (h > 800) {
    //   this.filters.page_count = 20
    // } else {
    //   this.filters.page_count = 10
    // }
  }
}